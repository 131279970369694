import { HttpClient, HttpHeaders         } from '@angular/common/http';
import { Injectable         } from '@angular/core';
import { BehaviorSubject, catchError, delay, map, Observable, retry, Subject, tap, throwError         } from 'rxjs';

import { environment        } from 'src/environments/environment';
import { registroExpediente } from '../models/registroExpediente';
import { MateriaResponse } from '../models/MateriaResponse';
import { MencionResponse } from '../models/MencionResponse';
import { DocumentoResponse } from '../models/DocumentoResponse';
import { RecursoResponse } from '../models/RecursoResponse';
import { AdopcionResponse } from '../models/AdopcionResponse';
import { MencionCAResponse } from '../models/MencionCAResponse';
import { IndicacionResponse } from '../models/IndicacionResponse';
import { RecursoCAResponse } from '../models/RecursoCAResponse';
import { RecursoCUResponse } from '../models/RecursoCUResponse';
import { CumplimientoResponse } from '../models/CumplimientoResponse';
import { listaexpediente } from '../models/ListaexpedienteReponse';
import { ReporteActos } from '../models/ReporteActos';
import { DenuncianteResponse } from '../models/DenuncianteResponse';
import { OrganoResponse } from '../models/OrganoResponse';
import { EstatuResponse } from '../models/EstatuResponse';
import { ConsejoResponse } from '../models/ConsejoResponse';
import { CargaDocumento, DocumentoCargaResponse } from '../models/DocumentoCarga';
import { PESResponse } from '../models/PESResponse';
import { Cargo } from '../models/Cargo';
import { Actuacion, MencionResponseActuacion } from '../models/ActuacionResponse';
import { Partes } from '../models/Partes';
import { DenunciasPartidos } from '../models/DenunciasPArtidos';
import { QuejasMateria } from '../models/Graficas/QuejasMateria';
import { MedidasCautelares } from '../models/Graficas/MedidasCautelares';
import { SolicitudMeCau } from '../models/Graficas/SolicitudMeCaut';
import { TotalDenunciantes } from '../models/Graficas/TotalDenunciantes';
import { TotalMedidas } from '../models/Graficas/TotalMedidas';
import { DeclaraIncompetente } from '../models/DeclaraIncompetente';
import { Cumplimientos } from '../models/Cumplimientos';
import { Indicaciones } from '../models/Indicaciones';
import { Materias } from '../models/Materias';
import { Menciones } from '../models/Menciones';
import { MencionesCA } from '../models/MencionesCA';
import { Recursos } from '../models/Recursos';
import { RecursosCA } from '../models/RecursosCA';
import { RecursosCU } from '../models/RecursosCU';
import { SubCategoria } from '../models/SubCategoria';
import { Submatery } from '../models/Graficas/Submatery';

@Injectable({
  providedIn: 'root'
})
export class ExpedienteService {
  private httpOptions = {

    headers: new HttpHeaders({

      'content-type': 'application/json',

      'encoding': 'UTF-8'

    })
  }
  private apiResponse(response: Response) {
    let body: any = response;
    return body || {};
  }
  myApiUrl=environment.apiUrl;
  
  private _refresh$ = new Subject<void>();
  list:registroExpediente[];
  private actualizarFormulario= new BehaviorSubject<registroExpediente>({}as any);

  constructor(private http:HttpClient) { }

  // guardaServicioExpediente(Expediente:registroExpediente):Observable<any>{
  //    return this.http.post<any>(`${this.myApiUrl}Registro`, Expediente);
  // }

  guardaServicioExpediente(formData){
    return this.http.post<any>(`${this.myApiUrl}Registro`, formData);
  }

  MostrarTramite(id:number){
    return this.http.get<any>(`${this.myApiUrl}Registro/GetByTramiteId/${id}`);
  }
  guardaServicioTramite(formData){
    return this.http.post<any>(`${this.myApiUrl}Registro/Tramites`, formData);
  }
  editaServicioTramite(formData){
    //const object = JSON.stringify(formData);
    console.log("FORM DATA SERVICE: ", formData);
    
    const httOptions = {headers : new HttpHeaders({
      enctype: 'multipart/form-data'
      //, responseType: "JSON"
      //, "Content-Type": "application/json; charset=utf-8"
    })}
    return this.http.put<any>(`${this.myApiUrl}Registro/UpdateTramites`, formData, httOptions);
  }

  guardaDocumentos(formData):Observable<any>{
    return this.http.post<any>(`${this.myApiUrl}Registro/GuardaDocs`, formData);
  }

  eliminaDocumento(Expediente:CargaDocumento):Observable<CargaDocumento>{
    return this.http.put<CargaDocumento>(this.myApiUrl + 'Registro/EliminaDoc' ,Expediente);
  }
  getInformacionDetalladaExpedienteByRegistroId(idregistro:number):Observable<any>{
    return this.http.get<any>(this.myApiUrl +'Registro/GetInformacionDetalladaExpedienteByRegistroId/'+ idregistro);
  }

  getActuacionesByRegistroId(idregistro:number):Observable<any>{
    return this.http.get<any>(this.myApiUrl +'Registro/GetActuacionesByRegistroId/'+ idregistro);
  }

  getDocsCargadosByRegistroId(idregistro:number):Observable<any>{
    return this.http.get<any>(this.myApiUrl +'Registro/GetDocsCargadosByRegistroId/'+ idregistro);
  }
  getTramitesByRegistroId(idregistro:number):Observable<any>{
    return this.http.get<any>(this.myApiUrl +'Registro/GetTramitesByRegistroId/'+ idregistro);
  }
  
  GetExpedienteByRegistroId(idregistro:number):Observable<any>{
    return this.http.get<any>(this.myApiUrl +'Registro/GetExpedienteByRegistroId/'+ idregistro);
  }
  obtenerExpedientes():Observable<any>{
    return this.http.get<any>(this.myApiUrl +'Registro');
    // this.http.get(this.myApiUrl +'Registro').toPromise()
    //   .then(data =>{
    //     this.list=data as registroExpediente[];
    //   });
  }

  obtenerUNExpediente(idregistro:number):Observable<any>{
    return this.http.get<any>(this.myApiUrl +'Registro/'+ idregistro);
  }

  obtenerDesactualizados(idOrgano,officeLocation){
    this.http.get(this.myApiUrl +`Registro/PorFecha/` +idOrgano+"/"+officeLocation).toPromise()
      .then(data =>{
        this.list=data as registroExpediente[];
      });
  }

  eliminarExpediente(idregistro: number):Observable<registroExpediente>{
    return this.http.delete<registroExpediente>(this.myApiUrl + 'Registro/'+ idregistro);
  }
  
  actualizarExpediente(formData){
    return this.http.put<any>(`${this.myApiUrl}Registro/` ,formData);
  // actualizarExpediente(formData:registroExpediente):Observable<registroExpediente>{
  //   return this.http.put<registroExpediente>(this.myApiUrl + 'Registro/' ,formData);
  }

  actualizarEstatus(idregistro : number){
    return this.http.put<any>(`${this.myApiUrl}Registro/ConcluirExpediente?registroId=${idregistro}`,idregistro );
  }
  CambiaEstatus(idregistro : number){
    return this.http.put<any>(`${this.myApiUrl}Registro/CambiaEstatus?registroId=${idregistro}`,idregistro );
  }
  AceptarExpediente(idregistro: number, aceptar: boolean) {
    return this.http.put<any>(`${this.myApiUrl}Registro/AceptarExpediente?registroId=${idregistro}&aceptar=${aceptar}`, {});
  }
  
  ListExpediente(Expediente:listaexpediente):Observable<any>{
    // console.log("EXpediente 23423", Expediente);
    return this.http.post<any>(`${this.myApiUrl}Registro/filter-report`, Expediente, this.httpOptions);
  }

  DocsExpediente(idregistro:number):Observable<DocumentoCargaResponse>{
    return this.http.get<DocumentoCargaResponse>(this.myApiUrl +'Registro/Documentos/'+ idregistro);
  }
  DeleteDocument(iddocumentoregistro:Number):Observable<any>{    
    return this.http.delete<any>(this.myApiUrl +`Registro/DeleteDocumentoRegistradoId/${iddocumentoregistro}`); 
  }

  actualizar(expediente){
    this.actualizarFormulario.next(expediente);
  }

  obtenerExpediente():Observable<registroExpediente>{
    return this.actualizarFormulario.asObservable();
  }

 
  
  //#region ==========> Reposición Expediente <==========
  CreateReposicionExpediente(datos: any): Observable<any>{
    return this.http.post<any>(this.myApiUrl +'reposicionExpediente/CreateReposicionExpediente', datos);
  }

  ObtReposicionExpedienteByRegistroId(idregistro:number):Observable<any>{
    return this.http.get<any>(this.myApiUrl +'reposicionExpediente/GetReposicionExpedienteByRegistroId/'+ idregistro);
  }
  
  UpdateReposicionExpediente(datos: any): Observable<any>{
    return this.http.get<any>(this.myApiUrl +'reposicionExpediente/UpdateReposicionExpediente', datos);
  }

    //#region ==========> Reasignación Expediente <==========
    CreateReasignacionExpediente(datos: any): Observable<any>{
      return this.http.post<any>(this.myApiUrl +'reasignaciones/CreateReasignacionExpediente', datos);
    }
  
    GetReasignacionByRegistroId(idregistro:number):Observable<any>{
      return this.http.get<any>(this.myApiUrl +'reasignaciones/GetReasignacionByRegistroId/'+ idregistro);
    }
    
    UpdateReasignacionExpediente(datos: any): Observable<any>{
      return this.http.get<any>(this.myApiUrl +'reasignaciones/UpdateReasignacion', datos);
    }


    //#region ==========> MATERIAS <==========
  readAllMaterias(): Observable<MateriaResponse>{
    return this.http.get<MateriaResponse>(`${this.myApiUrl}materias`).pipe(
      retry(3),
      delay(1000),
      catchError(this.handleError)
    );
  }
  //#region ==========> ACTUACIONES <==========
  readAllActuacion(): Observable<MencionResponseActuacion>{
    return this.http.get<MencionResponseActuacion>(`${this.myApiUrl}catalogos/GetAllTipoActuaciones`).pipe(
      retry(3),
      delay(1000),
      catchError(this.handleError)
    );
  }

  //#region ==========> MENCIONES <==========
  readAllMenciones(): Observable<MencionResponse>{
    return this.http.get<MencionResponse>(`${this.myApiUrl}menciones`).pipe(
      retry(3),
      delay(1000),
      catchError(this.handleError)
    );
  }

  //#region ==========> RECURSOS <==========
  readAllRecursos(): Observable<RecursoResponse>{
    return this.http.get<RecursoResponse>(`${this.myApiUrl}recursos`).pipe(
      retry(3),
      delay(1000),
      catchError(this.handleError)
    );
  }

  //#region ==========> DOCUMENTOS <==========
  readAllDocumentos(): Observable<DocumentoResponse>{
    return this.http.get<DocumentoResponse>(`${this.myApiUrl}documentos/GetAllDocumentos`).pipe(
      retry(3),
      delay(1000),
      catchError(this.handleError)
    );
  }

   //#region ==========> ADOPCIONES <==========
   
   readAllAdopciones(): Observable<AdopcionResponse>{
    return this.http.get<AdopcionResponse>(`${this.myApiUrl}adopciones`).pipe(
      retry(3),
      delay(1000),
      catchError(this.handleError)
    );
  }

   getActuacionXId(idActuacion): Observable<any>{
    return this.http.get<any>(`${this.myApiUrl}catalogos/GetByTipoActuacionId/`+idActuacion)
  }


    //#region ==========> MENCIONESCA <==========
    readAllMencionesCA(): Observable<MencionCAResponse>{
      return this.http.get<MencionCAResponse>(`${this.myApiUrl}mencionesCA`).pipe(
        retry(3),
        delay(1000),
        catchError(this.handleError)
      );
    }

     //#region ==========> INIDCACIONES <==========
     readAllIndicaciones(): Observable<IndicacionResponse>{
      return this.http.get<IndicacionResponse>(`${this.myApiUrl}indicaciones`).pipe(
        retry(3),
        delay(1000),
        catchError(this.handleError)
      );
    }

    //#region ==========> RECURSOS_CA <==========
    readAllRecursosCA(): Observable<RecursoCAResponse>{
      return this.http.get<RecursoCAResponse>(`${this.myApiUrl}recursosCA`).pipe(
        retry(3),
        delay(1000),
        catchError(this.handleError)
      );
    }

    //#region ==========> RECURSOS_CU <==========
    readAllRecursosCU(): Observable<RecursoCUResponse>{
      return this.http.get<RecursoCUResponse>(`${this.myApiUrl}recursosCU`).pipe(
        retry(3),
        delay(1000),
        catchError(this.handleError)
      );
    }

    //#region ==========> CUMPLIMIENTOS <==========
    readAllCumplimientos(): Observable<CumplimientoResponse>{
      return this.http.get<CumplimientoResponse>(`${this.myApiUrl}cumplimientos`).pipe(
        retry(3),
        delay(1000),
        catchError(this.handleError)
      );
    }

     //#region ==========> ORGANOS <==========
     readAllOrganos(): Observable<OrganoResponse>{
      return this.http.get<OrganoResponse>(`${this.myApiUrl}organos`).pipe(
        retry(3),
        delay(1000),
        catchError(this.handleError)
      );
    }

      //#region ==========> ESTATUS <==========
     readAllEstatus(): Observable<EstatuResponse>{
      return this.http.get<EstatuResponse>(`${this.myApiUrl}estatus`).pipe(
        retry(3),
        delay(1000),
        catchError(this.handleError)
      );
    }

  //   FilterFechaRegistro(RangoFechas:any):Observable<any>{
  //     return this.http.post<any>(`${this.myApiUrl}Registro/filter-FechaRegistro`, RangoFechas, this.httpOptions);
  //  }

  //#region ==========> REPORTE 2 <==========
  totalReportActos(fechaInicio,fechaFin): Observable<ReporteActos>{
    return this.http.get<ReporteActos>(`${this.myApiUrl}Registro/Obt_Report_Actos/`+fechaInicio+"/"+fechaFin).pipe(
      retry(3),
      delay(1000),
      catchError(this.handleError)
    );
  }
  //#region ==========> REPORTE DENUNCIANTES <==========
  totalReportDenunciantes(fechaInicio,fechaFin): Observable<DenuncianteResponse>{
    return this.http.get<DenuncianteResponse>(`${this.myApiUrl}Registro/Obt_Report_Denunciantes/`+fechaInicio+"/"+fechaFin).pipe(
      retry(3),
      delay(1000),
      catchError(this.handleError)
    );
  }
  AllSubMateria(idmateria,fechaInicio,fechaFin ): Observable<Submatery>{
    return this.http.get<Submatery>(`${this.myApiUrl}Registro/sub_category_mat/`+idmateria+"/"+fechaInicio+"/"+fechaFin)
  }
  //#region ==========> REPORTE CONSEJO <==========
  totalReportConsejos(fechaInicio,fechaFin): Observable<ConsejoResponse>{
    return this.http.get<ConsejoResponse>(`${this.myApiUrl}Registro/Obt_Report_QuejasxConsejo/`+fechaInicio+"/"+fechaFin).pipe(
      retry(3),
      delay(1000),
      catchError(this.handleError)
    );
  }
  
 //#region ==========> REPORTE PES <==========
 totalReportPES(): Observable<PESResponse>{
  return this.http.get<PESResponse>(`${this.myApiUrl}Registro/Obt_Report_PES`).pipe(
    retry(3),
    delay(1000),
    catchError(this.handleError)
  );
}

 //#region ==========> REPORTE PARTIDOS <==========
 DenunciasPartidos(RangoFechas): Observable<DenunciasPartidos>{
  return this.http.get<DenunciasPartidos>(`${this.myApiUrl}catalogos/DenunciasPartidos/${RangoFechas.FechaInicio}/${RangoFechas.FechaFinal}`,this.httpOptions).pipe(
    retry(3),
    delay(1000),
    catchError(this.handleError)
  );
}
 //#endregion
 //#region ==========> QUEJAS POR MATERIA <==========
 QuejaMaterias(RangoFechas): Observable<QuejasMateria>{
  return this.http.get<QuejasMateria>(`${this.myApiUrl}catalogos/QuejasMateria/${RangoFechas.FechaInicio}/${RangoFechas.FechaFinal}`,this.httpOptions).pipe(
    retry(3),
    delay(1000),
    catchError(this.handleError)
  );
}
 //#endregion
 //#region ==========> MEDIDAS CAUTELARES <==========
 MedidasCautelares(RangoFechas): Observable<MedidasCautelares>{
  return this.http.get<MedidasCautelares>(`${this.myApiUrl}catalogos/MedidasCautelares/${RangoFechas.FechaInicio}/${RangoFechas.FechaFinal}`,this.httpOptions).pipe(
    retry(3),
    delay(1000),
    catchError(this.handleError)
  );
}
 //#endregion
 //#region ==========> SOLICITUD MEDIDAS CAUTELARES <==========
 SolicitudMC(RangoFechas): Observable<SolicitudMeCau>{
  return this.http.get<SolicitudMeCau>(`${this.myApiUrl}catalogos/SolicitudMC/${RangoFechas.FechaInicio}/${RangoFechas.FechaFinal}`,this.httpOptions).pipe(
    retry(3),
    delay(1000),
    catchError(this.handleError)
  );
}
 //#endregion
 //#region ==========> SOLICITUD MEDIDAS CAUTELARES <==========
 TotalDenunciantes(RangoFechas): Observable<TotalDenunciantes>{
  return this.http.get<TotalDenunciantes>(`${this.myApiUrl}catalogos/TotalDenunciantes/${RangoFechas.FechaInicio}/${RangoFechas.FechaFinal}`,this.httpOptions).pipe(
    retry(3),
    delay(1000),
    catchError(this.handleError)
  );
}
 //#endregion
 //#endregion
 //#region ==========> SOLICITUD MEDIDAS CAUTELARES <==========
 TotalMedidas(RangoFechas): Observable<TotalMedidas>{
  return this.http.get<TotalMedidas>(`${this.myApiUrl}catalogos/TotalMedidas/${RangoFechas.FechaInicio}/${RangoFechas.FechaFinal}`,this.httpOptions).pipe(
    retry(3),
    delay(1000),
    catchError(this.handleError)
  );
}
 //#endregion

  FilterFechaRegistro(RangoFechas:any):Observable<any>{
      return this.http.post<any>(`${this.myApiUrl}Registro/filter-FechaRegistro`, RangoFechas, this.httpOptions).pipe(
        retry(3),
        delay(1000),
        catchError(this.handleError),
        map(this.apiResponse)
      );
  }
 

   postDocumentos(formData){
    return this.http.post(`${this.myApiUrl}api/documentos/acuse`, formData)
    .pipe(
      map(this.apiResponse),
      tap(()=>{
        this._refresh$.next();
      })
      );
  }
 

  //#endregion

  // readExpediente():Observable<registroExpediente>{
  //   return this.http.get<registroExpediente>(`${this.myApiUrl}Expediente`);
  // }
 //-----------------------------------CATALOGOS-------------------------------------//
//OBTENER CARGOS
getAllCargos(): Observable<Cargo[]>{
  return this.http.get<Cargo[]>(`${this.myApiUrl}catalogos/GetAllCargos`).pipe(
    retry(3),
    delay(1000),
    catchError(this.handleError)
  );
}

//CREAR CARGOS
createCargos(cargo: Cargo): Observable<Cargo>{
  return this.http.post<Cargo>(`${this.myApiUrl}catalogos/CreateCargo`, cargo);
  }

//ACTUALIZAR CARGOS
updateCargos(cargo: Cargo): Observable<Cargo>{
  return this.http.put<Cargo>(`${this.myApiUrl}catalogos/UpdateCargo`, cargo);
 }

//ELIMINAR CARGOS
  deleteCargos(cargoId: number): Observable<any>{
    return this.http.delete<any>(`${this.myApiUrl}catalogos/DeleteCargo/${cargoId}`);   
  }



  //OBTENER TIPO ACTUACIONES 
getAllTipoActuaciones(): Observable<Actuacion[]>{
  return this.http.get<Actuacion[]>(`${this.myApiUrl}catalogos/GetAllTipoActuaciones`).pipe(
    retry(3),
    delay(1000),
    catchError(this.handleError)
  );
}
//CREAR TIPO ACTUACIONES
createTipoActuaciones(actuacion: Actuacion): Observable<Actuacion>{
  return this.http.post<Actuacion>(`${this.myApiUrl}catalogos/CreateTipoActuacion`, actuacion);
  }

//ACTUALIZAR TIPO ACTUACIONES
updateTipoActuaciones(actuacion: Actuacion): Observable<Actuacion>{
  return this.http.put<Actuacion>(`${this.myApiUrl}catalogos/UpdateTipoActuacion`, actuacion);
 }

//ELIMINAR TIPO ATUACIONES  
  deleteTipoActuaciones(tipoActuacionId: number): Observable<any>{
    return this.http.delete<any>(`${this.myApiUrl}catalogos/DeleteTipoActuacion/${tipoActuacionId}`);   
  }
  
//OBTENER PARTES 
getAllPartes(): Observable<Partes[]>{
  return this.http.get<Partes[]>(`${this.myApiUrl}catalogos/GetAllPartes`).pipe(
    retry(3),
    delay(1000),
    catchError(this.handleError)
  );
}

getPartes(): Observable<Partes[]>{
  return this.http.get<Partes[]>(`${this.myApiUrl}catalogos/GetPartes`).pipe(
    retry(3),
    delay(1000),
    catchError(this.handleError)
  );
}
//CREAR PARTES
createPartes(parte: Partes): Observable<Partes>{
  return this.http.post<Partes>(`${this.myApiUrl}catalogos/CreateParte`, parte);
  }

//ACTUALIZAR PARTES
updatePartes(parte: Partes): Observable<Partes>{
  return this.http.put<Partes>(`${this.myApiUrl}catalogos/UpdateParte`, parte);
 }

//ELIMINAR PARTES 
  deletePartes(parteId: number): Observable<any>{
    return this.http.delete<any>(`${this.myApiUrl}catalogos/DeleteParte/${parteId}`);   
  }

  // CATALOGO DE CUMPLIMIENTO 
  //OBTENER CUMPLIMIENTOS 
  getAllCumplimientos(): Observable<Cumplimientos[]>{
    return this.http.get<Cumplimientos[]>(`${this.myApiUrl}cumplimientos/GetAllCumplimientos`).pipe(
      retry(3),
      delay(1000),
      catchError(this.handleError)
    );
  }

  //CREAR CUMPLIMIENTOS
  createCumplimientos(cumplimiento: Cumplimientos): Observable<Cumplimientos>{
    return this.http.post<Cumplimientos>(`${this.myApiUrl}cumplimientos/CreateCumplimiento`, cumplimiento);
  }

  //ACTUALIZAR CUMPLIMIENTOS
  updateCumplimientos(cumplimiento: Cumplimientos): Observable<Cumplimientos>{
    return this.http.put<Cumplimientos>(`${this.myApiUrl}cumplimientos/UpdateCumplimiento`, cumplimiento);
  }
  //DELETE CUMPLIMIENTOS
  deleteCumplimientos(ccumplimiento: number): Observable<any>{
    return this.http.delete<any>(`${this.myApiUrl}cumplimientos/DeleteCumplimiento/${ccumplimiento}`);   
  }


  // CATALOGO DE CUMPLIMIENTO 
  //OBTENER INDICACIONES 
  getAllIndicaciones(): Observable<Indicaciones[]>{
    return this.http.get<Indicaciones[]>(`${this.myApiUrl}indicaciones/GetAllIndicaciones`).pipe(
      retry(3),
      delay(1000),
      catchError(this.handleError)
    );
  }

  //CREAR INDICACIONES
  createIndicaciones(indicacion: Indicaciones): Observable<Indicaciones>{
    return this.http.post<Indicaciones>(`${this.myApiUrl}indicaciones/CreateIndicacion`, indicacion);
  }

  //ACTUALIZAR INDICACIONES
  updateIndicaciones(indicacion: Indicaciones): Observable<Indicaciones>{
    return this.http.put<Indicaciones>(`${this.myApiUrl}indicaciones/UpdateIndicacion`, indicacion);
  }
  //DELETE INDICACIONES
  deleteIndicaciones(indicacionId: number): Observable<any>{
    return this.http.delete<any>(`${this.myApiUrl}indicaciones/DeleteIndicacion/${indicacionId}`);   
  }

  // CATALOGO DE MATERIAS 
  //OBTENER MATERIAS 
  getAllMaterias(): Observable<Materias[]>{
    return this.http.get<Materias[]>(`${this.myApiUrl}materias/GetAllMaterias`).pipe(
      retry(3),
      delay(1000),
      catchError(this.handleError)
    );
  }

  //CREAR MATERIAS
  createMaterias(materia: Materias): Observable<Materias>{
    return this.http.post<Materias>(`${this.myApiUrl}materias/CreateMateria`, materia);
  }

  //ACTUALIZAR MATERIAS
  updateMaterias(materia: Materias): Observable<Materias>{
    return this.http.put<Materias>(`${this.myApiUrl}materias/UpdateMateria`, materia);
  }
  //DELETE MATERIAS
  deleteMaterias(materiaId: number): Observable<any>{
    return this.http.delete<any>(`${this.myApiUrl}materias/DeleteMateria/${materiaId}`);   
  }

  // CATALOGO DE MENCIONES
  //OBTENER MENCIONES
  getAllMenciones(): Observable<Menciones[]>{
    return this.http.get<Menciones[]>(`${this.myApiUrl}menciones/GetAllMenciones`).pipe(
      retry(3),
      delay(1000),
      catchError(this.handleError)
    );
  }

  //CREAR MENCIONES
  createMenciones(mencion: Menciones): Observable<Menciones>{
    return this.http.post<Menciones>(`${this.myApiUrl}menciones/CreateMencion`, mencion);
  }
  
  //ACTUALIZAR MENCIONES
  updateMenciones(mencion: Menciones): Observable<Menciones>{
    return this.http.put<Menciones>(`${this.myApiUrl}menciones/UpdateMencion`, mencion);
  }
  //DELETE MENCIONES
  deleteMencion(mencionId: number): Observable<any>{
    return this.http.delete<any>(`${this.myApiUrl}menciones/DeleteMencion/${mencionId}`);   
  }


    // CATALOGO DE MENCIONESCA
  //OBTENER MENCIONESCA
  getAllMencionesCA(): Observable<MencionesCA[]>{
    return this.http.get<MencionesCA[]>(`${this.myApiUrl}mencionesCA/GetAllMencionesCA`).pipe(
      retry(3),
      delay(1000),
      catchError(this.handleError)
    );
  }

  //CREAR MENCIONESCA
  createMencionesCA(mencionCA: Menciones): Observable<Menciones>{
    return this.http.post<Menciones>(`${this.myApiUrl}mencionesCA/CreateMencionCA`, mencionCA);
  }
  
  //ACTUALIZAR MENCIONESCA
  updateMencionesCA(mencionCA: MencionesCA): Observable<MencionesCA>{
    return this.http.put<MencionesCA>(`${this.myApiUrl}mencionesCA/UpdateMencionCA`, mencionCA);
  }
  //DELETE MENCIONESCA
  deleteMencionCA(mencionCAId: number): Observable<any>{
    return this.http.delete<any>(`${this.myApiUrl}mencionesCA/DeleteMencionCA/${mencionCAId}`);   
  }


  // CATALOGO DE RECURSOS
  //OBTENER RECURSOS
  getAllRecursos(): Observable<Recursos[]>{
    return this.http.get<Recursos[]>(`${this.myApiUrl}recursos/GetAllRecursos`).pipe(
      retry(3),
      delay(1000),
      catchError(this.handleError)
    );
  }

  //CREAR RECURSOS
  createRecursos(recurso: Recursos): Observable<Recursos>{
    return this.http.post<Recursos>(`${this.myApiUrl}recursos/CreateRecurso`, recurso);
  }
  
  //ACTUALIZAR RECURSOS
  updateRecursos(recurso: Recursos): Observable<Recursos>{
    return this.http.put<Recursos>(`${this.myApiUrl}recursos/UpdateRecurso`, recurso);
  }
  //DELETE RECURSOS
  deleteRecursos(recursoId: number): Observable<any>{
    return this.http.delete<any>(`${this.myApiUrl}recursos/DeleteRecurso/${recursoId}`);   
  }

   // CATALOGO DE RECURSOSCA
  //OBTENER RECURSOSCA
  getAllRecursosCA(): Observable<RecursosCA[]>{
    return this.http.get<RecursosCA[]>(`${this.myApiUrl}recursosCA/GetAllRecursosCA`).pipe(
      retry(3),
      delay(1000),
      catchError(this.handleError)
    );
  }

  //CREAR RECURSOSCA
  createRecursosCA(recursoCA: RecursosCA): Observable<RecursosCA>{
    return this.http.post<RecursosCA>(`${this.myApiUrl}recursosCA/CreateRecursoCA`, recursoCA);
  }
  
  //ACTUALIZAR RECURSOSCA
  updateRecursosCA(recursoCA: RecursosCA): Observable<RecursosCA>{
    return this.http.put<RecursosCA>(`${this.myApiUrl}recursosCA/UpdateRecursoCA`, recursoCA);
  }
  //DELETE RECURSOSCA
  deleteRecursosCA(recursoCAId: number): Observable<any>{
    return this.http.delete<any>(`${this.myApiUrl}recursosCA/DeleteRecursoCA/${recursoCAId}`);   
  }

    // CATALOGO DE RECURSOSCU
  //OBTENER RECURSOSCU
  getAllRecursosCU(): Observable<RecursosCU[]>{
    return this.http.get<RecursosCU[]>(`${this.myApiUrl}recursosCU/GetAllRecursosCU`).pipe(
      retry(3),
      delay(1000),
      catchError(this.handleError)
    );
  }

  //CREAR RECURSOSCU
  createRecursosCU(recursoCU: RecursosCU): Observable<RecursosCU>{
    return this.http.post<RecursosCU>(`${this.myApiUrl}recursosCU/CreateRecursoCU`, recursoCU);
  }
  
  //ACTUALIZAR RECURSOSCU
  updateRecursosCU(recursoCU: RecursosCU): Observable<RecursosCU>{
    return this.http.put<RecursosCU>(`${this.myApiUrl}recursosCU/UpdateRecursoCU`, recursoCU);
  }
  //DELETE RECURSOSCU
  deleteRecursosCU(recursoCUId: number): Observable<any>{
    return this.http.delete<any>(`${this.myApiUrl}recursosCU/DeleteRecursoCU/${recursoCUId}`);   
  }

      // CATALOGO DE SUBCATEGORIA
  //OBTENER SUBCATEGORIA
  getAllSubCategorias(): Observable<SubCategoria[]>{
    return this.http.get<SubCategoria[]>(`${this.myApiUrl}subCategorias/GetAllSubCategorias`).pipe(
      retry(3),
      delay(1000),
      catchError(this.handleError)
    );
  }

  //CREAR SUBCATEGORIA
  createSubCategorias(subcategoria: SubCategoria): Observable<SubCategoria>{
    return this.http.post<SubCategoria>(`${this.myApiUrl}subCategorias/CreateSubCategoria`, subcategoria);
  }
  
  //ACTUALIZAR SUBCATEGORIA
  updateSubCategorias(subcategoria: SubCategoria): Observable<SubCategoria>{
    return this.http.put<SubCategoria>(`${this.myApiUrl}subCategorias/UpdateSubCategoria`, subcategoria);
  }
  //DELETE SUBCATEGORIA
  deleteSubCategoria(subCategoriaId: number): Observable<any>{
    return this.http.delete<any>(`${this.myApiUrl}subCategorias/DeleteSubCategoria/${subCategoriaId}`);   
  }




  
//Reposición Expediente 
//POST


  handleError(error) {
      let statusError = '';
      if (error.error instanceof ErrorEvent) {
        // client-side error
        statusError = error.status;
      } else {
        // server-side error
        statusError = error.status;
      }
      return throwError(() => new Error(statusError))
    }

    getUrlFirmada(filePath) {

      return this.http.get<any>(`${this.myApiUrl}documentos/GeneratePreSignedURL?filePath=${filePath}`);
  
    }

}
