import { Component, NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";

import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { MicrosoftLoginGuard } from "./pages/auth/guards/microsoft-login.guard";
import { MicrosoftRolesGuard } from './pages/auth/guards/microsoft-roles.guard';
import { EditarExpedientesComponent } from "./pages/emision-expedientes/editar-expedientes/editar-expedientes.component";
import { permisosMenuUser } from "./pages/auth/constants/menuUsers.constant";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'inicio',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'login',
        loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [MicrosoftLoginGuard],
    // Arreglo de roles de usuario permitidos
    // data: {
    //   rolUsuario: ['Usuarios.Admin', 'Usuarios.Consulta', 'Usuarios.Lectura','SuperAdmin']
    // },
    children: [
      {
        path: 'inicio',
        canActivate: [MicrosoftRolesGuard],
        data: {
          rolUsuario: permisosMenuUser.INICIO
        },
        loadChildren: () => import('./pages/inicio/inicio.module').then(m => m.InicioModule)
      },
      {
        path:'expedientes',
        canActivate: [MicrosoftRolesGuard],
        data: {
          rolUsuario: [ 'Usuarios.SuperAdmin', 'Usuarios.Supervisor']
        },
        loadChildren: ()  => import('./pages/emision-expedientes/emision-expedientes.module').then(m => m.EmisionExpedientesModule)
      },
      {
        path:'reporeg',
        canActivate: [MicrosoftRolesGuard],
        data: {
          rolUsuario: [ 'Usuarios.SuperAdmin', 'Usuarios.Supervisor']
        },
        loadChildren: ()  => import('./pages/reportes/reportes.module').then(m => m.ReportesModule)
      },
      {
        path:'catalogo',
        canActivate: [MicrosoftRolesGuard],
        data: {
          rolUsuario: [ 'Usuarios.SuperAdmin', 'Usuarios.Supervisor']
        },
        loadChildren: ()  => import('./pages/catalogos/catalogos.module').then(m => m.CatalogosModule)
      }
      // {
      //   path: 'expedientes',
      //   loadChildren: () => import('./pages/emisionExpedientes/crea-expediente.module').then(m => m.emisionExpedientesModule)
      // }
    ]
  },
  {
    path: '**',
    redirectTo: 'inicio'
  },
  
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
