import { MenuItem } from "../interface/MenuItem.interface";
import { allowedUserRol } from "./AllowedUserRol.function";

export function checkMenuVisibility(menuitem: MenuItem): boolean {
    if (!menuitem.permission) {
      // Si no tiene definida la propiedad "permission", se muestra el menú
      return true;
    }

    if (menuitem.type === 'link') {
      return allowedUserRol(this.rolesUsuario, menuitem.permission);
    }

    if (menuitem.type === 'sub') {
      if (!menuitem.subItems || menuitem.subItems.length === 0) {
        return false;
      }

      return menuitem.subItems.some((subItem) => this.checkMenuVisibility(subItem));
    }

    return false;
}