import { rolesUsuario } from "./rolesUsers.constant"

type options = { [Key: string]: Readonly<string[]> }

export const permisosMenuUser: options = {
  //#region MENÚ INICIO
  INICIO: [
    rolesUsuario.ADMINISTRADOR,
    rolesUsuario.CAPTURAJURIDICO,
    rolesUsuario.CAPTURACONSEJOS,
  ],
  //#endregion

  //#region MENÚ NUEVO
  NUEVOEXPEDIENTES: [    
    rolesUsuario.ADMINISTRADOR,
    rolesUsuario.CAPTURAJURIDICO,
    rolesUsuario.CAPTURACONSEJOS,],
  //#endregion

  //#region MENÚ BUSCAR
  BUSCAREXPEDIENTES: [
    rolesUsuario.ADMINISTRADOR,
    rolesUsuario.CAPTURAJURIDICO,
    rolesUsuario.CAPTURACONSEJOS,
    rolesUsuario.CONSULTACONSEJOS,
    rolesUsuario.CONSULTAJURIDICO
  ],
  //#endregion

  //#region MENÚ REPORTES
  REPORTES: [rolesUsuario.CAPTURAJURIDICO,rolesUsuario.CONSULTAJURIDICO,rolesUsuario.ADMINISTRADOR,],
  //#endregion
  //#region MENÚ REPORTES
  MENSAJEREP: [
    rolesUsuario.CAPTURACONSEJOS,
    rolesUsuario.CONSULTACONSEJOS,
    rolesUsuario.CONSULTAJURIDICO],
  //#endregion
  //#region MENÚ CATALOGO
  MENSAJECATA: [rolesUsuario.CAPTURAJURIDICO,
    rolesUsuario.CAPTURACONSEJOS,
    rolesUsuario.CONSULTACONSEJOS,
    rolesUsuario.CONSULTAJURIDICO],
  //#endregion

  //#region MENÚ CATALOGOS
  CATALOGOS: [rolesUsuario.ADMINISTRADOR],
  //#endregion



} as const
