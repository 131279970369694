import { Component, Input } from '@angular/core';
import { MenuItem } from '../../interface/MenuItem.interface';
import { checkMenuVisibility } from '../../functions/CheckMenuVisibility.function';
import { getCollapseId } from '../../functions/GetCollapseId.function';

@Component({
  selector: 'app-submenu',
  templateUrl: './submenu.component.html',
  styleUrls: ['./submenu.component.scss']
})
export class SubmenuComponent {
  @Input() subItems: MenuItem[] = [];
  @Input() rolesUsuario: string[] = []; 

  public checkMenuVisibility: (menuitem: MenuItem) => boolean = checkMenuVisibility;
  public getCollapseId: (menuItem: MenuItem) => string = getCollapseId;

}
